<template>
  <div>
 
    <Grid />

  </div>
</template>
<script>
import Grid from "@/views/main/views/category/components/Grid.vue";

export default {
  name: "CategoryList",
  components: {
    Grid,
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
  
  
  